@use '@angular/material' as mat;
@use 'sass:map';

$_palettes: (
	primary: (
		0: #000000,
		10: #1b1c1c,
		20: #303030,
		25: #3b3b3b,
		30: #464747,
		35: #525252,
		40: #5e5e5e,
		50: #777777,
		60: #919090,
		70: #acabab,
		80: #c8c6c6,
		90: #e4e2e2,
		95: #f2f0f0,
		98: #fbf9f8,
		99: #fefcfb,
		100: #ffffff,
	),
	secondary: (
		0: #000000,
		10: #1b1c1c,
		20: #303031,
		25: #3b3b3c,
		30: #464747,
		35: #525252,
		40: #5e5e5e,
		50: #777777,
		60: #919190,
		70: #ababab,
		80: #c7c6c6,
		90: #e3e2e2,
		95: #f2f0f0,
		98: #faf9f9,
		99: #fdfcfb,
		100: #ffffff,
	),
	tertiary: (
		0: #000000,
		10: #001b3f,
		20: #002f66,
		25: #003a7a,
		30: #00458f,
		35: #0050a5,
		40: #005cbb,
		50: #3376d6,
		60: #5290f2,
		70: #7cabff,
		80: #abc7ff,
		90: #d7e2ff,
		95: #ecf0ff,
		98: #f9f9ff,
		99: #fdfbff,
		100: #ffffff,
	),
	neutral: (
		0: #000000,
		10: #1c1b1b,
		20: #313030,
		25: #3c3b3b,
		30: #484646,
		35: #535252,
		40: #605e5e,
		50: #797776,
		60: #929090,
		70: #adaaaa,
		80: #c9c6c5,
		90: #e5e2e1,
		95: #f4f0ef,
		98: #fcf8f8,
		99: #f6feff,
		100: #ffffff,
		4: #0e0e0e,
		6: #141313,
		12: #201f1f,
		17: #2a2a2a,
		22: #353434,
		24: #3a3939,
		87: #ddd9d8,
		92: #ebe7e7,
		94: #f1edec,
		96: #f7f3f2,
	),
	neutral-variant: (
		0: #000000,
		10: #191c1d,
		20: #2d3132,
		25: #383c3d,
		30: #444748,
		35: #4f5354,
		40: #5b5f60,
		50: #747878,
		60: #8e9192,
		70: #a9acac,
		80: #c4c7c7,
		90: #e0e3e3,
		95: #eff1f2,
		98: #f7fafa,
		99: #fafdfd,
		100: #ffffff,
	),
	error: (
		0: #000000,
		10: #410002,
		20: #690005,
		25: #7e0007,
		30: #93000a,
		35: #a80710,
		40: #ba1a1a,
		50: #de3730,
		60: #ff5449,
		70: #ff897d,
		80: #ffb4ab,
		90: #ffdad6,
		95: #ffedea,
		98: #fff8f7,
		99: #fffbff,
		100: #ffffff,
	),
);

$_rest: (
	secondary: map.get($_palettes, secondary),
	neutral: map.get($_palettes, neutral),
	neutral-variant: map.get($_palettes,  neutral-variant),
	error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);
$_error: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
	color: (
		theme-type: light,
		primary: $_primary,
		tertiary: $_tertiary,
	),
	typography: (
		brand-family: 'Inter, system-ui, sans-serif',
		bold-weight: 900
	),
	density: (
		scale: -1
	)
));

$dark-theme: mat.define-theme((
	color: (
		theme-type: dark,
		primary: $_primary,
		tertiary: $_tertiary,
	),
	typography: (
		brand-family: 'Inter, system-ui, sans-serif',
		bold-weight: 900
	),
	density: (
		scale: -1
	)
));


:root {
	--mat-toolbar-standard-height: 50px;
	--mat-toolbar-mobile-height: 44px;

	body {
		@include mat.core();
		@include mat.all-component-themes($dark-theme);

		@media (prefers-color-scheme: light) {
			@include mat.all-component-colors($light-theme);
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:focus {
		transition: background-color 0s 600000s, color 0s 600000s !important;
	}

	.text-tertiary {
		color: map.get($_tertiary, 60);
	}

	.mat-mdc-card-title {
		margin-bottom: 1em;
		margin-top: 1em;
	}

	.mat-mdc-form-field {
		display: inherit;
	}

}

@media (prefers-color-scheme: light) {
	@include mat.color-variants-backwards-compatibility($light-theme);

	body {
		background-color: white !important;
	}

	.tertiary-button {
		@include mat.button-color($light-theme, $color-variant: tertiary);
	}
}

@media (prefers-color-scheme: dark) {
	@include mat.color-variants-backwards-compatibility($dark-theme);

	body {
		background-color: black !important;
	}

	.mdc-button--raised:not(:disabled), .mdc-button--unelevated:not(:disabled) {
		background-color: mat.get-theme-color($dark-theme, primary, 25) !important;
	}

	.tertiary-button {
		@include mat.button-color($dark-theme, $color-variant: tertiary);
	}
}
