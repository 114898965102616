@use 'inter';
@use 'assets/custom-icons/custom-icons';
@use 'material';

@tailwind base;

@layer base {
	html {
		@apply text-black;
	}
}

@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: Inter, system-ui, sans-serif;
	}
}

html, body {
	height: 100%;
}

//*******************************
//** Material / tailwind fixes **
//*******************************
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
.mdc-notched-outline__notch {
	border-right-style: hidden;
}

.mat-mdc-input-element {
	box-shadow: none !important;
}

.sticky {
	position: sticky !important;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
	padding: 0;
	border: none;
}

.icon-spin {
	animation: spin-animation 1s infinite;
	animation-timing-function: linear;
	display: inline-block;
}

@keyframes spin-animation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
