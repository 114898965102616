@use "sass:map";

$custom-icons-font: "custom-icons";

@font-face {
	font-family: $custom-icons-font;
	src: url("./custom-icons.ttf?f1be1e7fd7646b921b783552ac7fdca7") format("truetype"),
url("./custom-icons.woff?f1be1e7fd7646b921b783552ac7fdca7") format("woff"),
url("./custom-icons.woff2?f1be1e7fd7646b921b783552ac7fdca7") format("woff2");
	font-display: swap;
}


i[class^="icon-"]:before, i[class*="icon-"]:before, [class*="icon-"]:before {
	//noinspection CssNoGenericFontName
	font-family: custom-icons !important;
	font-style: normal;
	font-size: 24px;
	font-weight: normal !important;
	font-variant: normal;
	text-transform: none;
	line-height: 24px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$custom-icons-map: (
	"google": "\f101",
);

.icon-google:before {
	content: map.get($custom-icons-map, "google");
}
